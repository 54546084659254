import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import moment from "moment";
import { DATE_FORMAT } from "./constants";

const toPascalCase = (str: string) => {
  if (str) {
    str = str.replace(/_/g, " ");
    str = str.toLowerCase();
    if (/^[a-z\d]+$/i.test(str)) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str
      .replace(
        /([a-z\d])([a-z\d]*)/gi,
        (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
      )
      .replace(/[^a-z\d]/gi, " ");
  }
  return str;
};

const groupBy = (array: Array<any>, key: string) => {
  return array.reduce((result: any, currentValue: any) => {
    if (currentValue[key]) {
      (result[toPascalCase(currentValue[key])] =
        result[toPascalCase(currentValue[key])] || []).push(currentValue);
    }
    return result;
  }, {});
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const formatAmount = (amount: number, currency:string): string => {
  return amount.toLocaleString("en-US", { style: "currency", currency });
};

const renameObjKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

const getSessionInfo = () => {
  return JSON.parse(
    window?.localStorage.getItem("session") || "{}"
  );
};

const setTimeAgoStringFromDate = (timestamp) => {
  const now = new Date();
  const seconds = Math.floor((now.valueOf() - timestamp.valueOf()) / 1000);

  // Define time intervals in seconds
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;

  if (seconds < minute) {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  } else if (seconds < hour) {
    const minutes = Math.floor(seconds / minute);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (seconds < day) {
    const hours = Math.floor(seconds / hour);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (seconds < day * 2) {
    // Less than 2 days ago, show "1 day ago"
    return `1 day ago`;
  } else {
    // For dates older than 1 day, return the date string
    const localDateFormat = moment.utc(timestamp).local().format('MM/DD/YYYY');
    return localDateFormat;
  }
}

export { groupBy, toPascalCase, Item, formatAmount, renameObjKeys, getSessionInfo, setTimeAgoStringFromDate};