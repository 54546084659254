import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface PDFViewModalProps {
    isOpen: boolean,
    onClose: () => void;
    pdf: PDFDetail; 
};

export type PDFDetail = {
    fileName: string;
}

const PDFViewModal = (props: PDFViewModalProps) => {
    const {isOpen, onClose, pdf} = props;

    const pdfFileName = encodeURIComponent(pdf.fileName);

    const pdfLink = `${process.env.REACT_APP_API_URL}/Attachment/Preview?fileName=${encodeURIComponent(pdfFileName)}`;

    const [error, setError] = useState(null);

    useEffect(() => {
        if (!pdf) return;

        const checkPDF = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/Attachment/Preview?fileName=${encodeURIComponent(pdfFileName)}`);
                const contentType = response.headers.get("content-type");

                if (contentType && contentType.includes("application/json")) {
                    setError("The PDF You are Trying to Download is Not in SharePoint");
                } else {
                    setError(null);
                }
            } catch (err) {
                setError("Failed to load PDF. Please try again later.");
            }
        };

        checkPDF();
        
    }, [pdf]);

    //if (!isOpen) return null;

    return(
        <>
            <div className="pdf-overlay">
                <div className="pdf-container">
                    <span className="close-btn" onClick={onClose}>&times;</span>
                    
                    {error ? 
                        <Box sx={{
                            backgroundColor: "gray",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <Typography>
                                {error}
                            </Typography>
                        </Box> :
                    <>
                        <iframe
                            src={`${pdfLink}`}
                            width="100%"
                            height="98%"
                            style={{ border: 'none' }}
                        />
                    </>
                    }
                </div>
            </div>
        </>
    )
};

export default PDFViewModal;