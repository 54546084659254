import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttachmentSelectedFilters } from '../models/Attachments/AttachmentListRequest';

type FiltersState = {
    selectedFilterOptions: any;
    selectedFilterValues: AttachmentSelectedFilters;
}

type AttachmentListState = {
    attachmentListView:FiltersState;
} 

const initialSelectedFilters: AttachmentSelectedFilters = {
  hideReleasedToInriver: true,
  hideArchivedDevelopment: true,
};

const initialState: AttachmentListState = {
  attachmentListView: {
    selectedFilterOptions: {},
    selectedFilterValues: initialSelectedFilters,
  }
};

const attachmentFilters = createSlice({
  name: 'attachmentFilters',
  initialState,
  reducers: {
    updateAttachmentViewSelectedFilterOptions(state, action: PayloadAction<any>) {
      state.attachmentListView.selectedFilterOptions = action.payload;
    },
    updateAttachmentViewSelectedFilterValues(state, action: PayloadAction<AttachmentSelectedFilters>) {
      state.attachmentListView.selectedFilterValues = action.payload;
    },
  },
});

export const { 
  updateAttachmentViewSelectedFilterOptions,
  updateAttachmentViewSelectedFilterValues
 } = attachmentFilters.actions;

export default attachmentFilters.reducer;