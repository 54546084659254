import { useEffect, useMemo, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import { AttachmentType } from "../../../models/AttachmentType";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { SessionDetails } from "../../../models/SessionDetails";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import {
  GRID_ACTION_DELETE_ICON,
  GRID_ACTION_EDIT_ICON,
} from "../../../components/GridUtilityComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import AttachmentTypeModal from "./AttachmentTypeModal";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function () {
  const [attachmentTypeList, setAttachmentTypeList] = useState<
    AttachmentType[]
  >([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const formDefaultValue = {
    id: 0,
    attachType: "",
    prefix: "",
    lvl: "",
    isActive: true,
  };

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const AttatchmentTypeGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id" },
    { field: "attachType", headerName: "Attachment Type", width: 250 },
    // { field: "family", headerName: "Family", width: 100 },
    // { field: "item", headerName: "Item", width: 100 },
    { field: "prefix", headerName: "Prefix", width: 100 },
    { field: "lvl", headerName: "Level", width: 50 },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable:false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={(e) => {
              params.api.setEditCellValue(
                { id: params.id, field: params.field },
                e.target.checked
              );
              updateAttchmentActive(params.row, e.target.checked);
            }}
            color="primary"
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    // {
    //   field: "updatedDate",
    //   headerName: "Updated Date",
    //   width: 200,
    //   valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClick),
        GRID_ACTION_DELETE_ICON(params, handleDeleteClick),
      ],
    },
  ];

  const handleEditClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteClick = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getAttachmentResponse = await api.get<ApiResponse<AttachmentType[]>>(
      "/Picklist/attachment-types"
    );

    const sortedList = getAttachmentResponse?.data?.sort(
      (rec1, rec2) => rec2.id - rec1.id
    );
    setAttachmentTypeList(sortedList || []);
  };

  const updateAttchmentActive = (
    attachmentType: AttachmentType,
    active: boolean
  ) => {
    const data = {
      ...attachmentType,
      isActive: active,
    };

    api
      .put<ApiResponse<boolean>>("/Picklist/attachment-type", data)
      .then(async (response: any) => {
        if (response.isSuccess) {
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {});
  };

  const formik = useFormik({
    initialValues: formDefaultValue,
    validateOnChange: true,
    validationSchema: Yup.object({
      attachType: Yup.string()
        .trim()
        .required("Attachment Type is Required")
        .min(1, "Attachment Type must be at least 1 characters")
        .max(40, "Attachment Type must be at most 40 characters")
        .test(
          "check-rank-duplicate",
          "Attachment Type already exists",
          (value) => {
            return !attachmentTypeList.some(
              (item) =>
                item.attachType.trim().toLowerCase() ===
                  value?.trim()?.toLowerCase() && item.id !== formik.values.id
            );
          }
        ),
      prefix: Yup.string()
        .trim()
        .min(1, "Prefix must be at least 1 characters")
        .max(40, "Prefix must be at most 40 characters")
        .required("Prefix is Required")
        .test("check-rank-duplicate", "Prefix already exists", (value) => {
          return !attachmentTypeList.some(
            (item) =>
              item.prefix.trim().toLowerCase() === value.trim().toLowerCase() &&
              item.id !== formik.values.id
          );
        }),
        lvl: Yup.string()
        .trim()
        .required("Attachment Level is Required"),
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true); // Set disableSubmit to true when the form is submitted
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/attachment-type", {
            id: values.id,
            prefix: values.prefix.trim(),
            lvl: values.lvl.trim(),
            attachType: values.attachType.trim(),
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/attachment-type", {
            prefix: values.prefix.trim(),
            attachType: values.attachType.trim(),
            lvl: values.lvl.trim(),
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  const deleteAttachmentType = () => {
    api
      .delete<ApiResponse<number>>(
        `/Picklist/attachment-type/${formik.values.id}`
      )
      .then(async (response: any) => {
        formik.resetForm();
        if (response.isSuccess) {
          setDeleteModalOpen(false);
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {
        formik.resetForm();
      });
  };

  return (
    <>
      <AttachmentTypeModal
        isOpen={showAddUpdateModal}
        onClose={handleCloseModal}
        formik={formik}
        isButtonDisabled={disableSubmit}
      />

      <DeleteValidationModal
        addModalOpen={deleteModalOpen}
        handleCloseModal={handleCloseModal}
        deleteSubmit={deleteAttachmentType}
        fieldIdentifier={"Attachment Type"}
      />
      {manageAppSetting && <Button
        variant="contained"
        onClick={handleAddClickOpen}
      >
        Add Attachment Type
      </Button>}
      <SettingGrid
        data={attachmentTypeList}
        column={AttatchmentTypeGridCollumns}
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
