import { useEffect, useState } from "react";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { FactStatus } from "../../../models/FactStatus";
import { ApiResponse } from "../../../models/ApiResponse";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { useFormik } from "formik";
import * as Yup from "yup";
import FactoryStatusFormModal from "./FactoryStatusFormModal";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import {
  GRID_ACTION_DELETE_ICON,
  GRID_ACTION_EDIT_ICON,
} from "../../../components/GridUtilityComponents";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function () {
  const [rows, setRows] = useState<FactStatus[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const formDefaultValue = {
    id: 0,
    description: "",
    isActive: true,
  };

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const FactStatusGridColumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "description", headerName: "Description", width: 350 },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable: false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={(e) => {
              params.api.setEditCellValue(
                { id: params.id, field: params.field },
                e.target.checked
              );
              updateFactStatusActive(params.row, e.target.checked);
            }}
            color="primary"
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    // {
    //   field: "updatedDate",
    //   headerName: "Updated Date",
    //   width: 200,
    //   valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    // },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClick),
        GRID_ACTION_DELETE_ICON(params, handleDeleteClick),
      ],
    },
  ];

  const fetchData = async () => {
    const getFactStatusResponse = await api.get<ApiResponse<FactStatus[]>>(
      "/Picklist/factory-statuses"
    );
    const sortedList = getFactStatusResponse?.data?.sort(
      (rec1, rec2) => rec2.id - rec1.id
    );
    setRows(sortedList || []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteClick = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateFactStatusActive = (
    attachmentType: FactStatus,
    active: boolean
  ) => {
    const data = {
      ...attachmentType,
      isActive: active,
    };

    api
      .put<ApiResponse<boolean>>("/Picklist/factory-status", data)
      .then(async (response: any) => {
        if (response.isSuccess) {
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {});
  };

  const formik = useFormik({
    initialValues: formDefaultValue,
    validationSchema: Yup.object({
      description: Yup.string()
        .trim()
        .min(1, "Status Name must be at least 1 characters")
        .max(40, "Status Name must be at most 40 characters")
        .required("Factory Status is Required")
        .test(
          "check-rank-duplicate",
          "Factory Status description already exists",
          (value) => {
            return !rows.some(
              (item) =>
                item.description.trim().toLowerCase() ===
                  value.trim().toLowerCase() && item.id !== formik.values.id
            );
          }
        ),
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true); // Set disableSubmit to true when the form is submitted
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/factory-status", {
            id: values.id,
            description: values.description.trim(),
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/factory-status", {
            description: values.description,
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  const deleteFactStatus = () => {
    api
      .delete<ApiResponse<number>>(
        `/Picklist/factory-status/${formik.values.id}`
      )
      .then(async (response: any) => {
        if (response.isSuccess) {
          setDeleteModalOpen(false);
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <FactoryStatusFormModal
        isOpen={showAddUpdateModal}
        onClose={handleCloseModal}
        formik={formik}
        isButtonDisabled={disableSubmit}
      />

      <DeleteValidationModal
        addModalOpen={deleteModalOpen}
        handleCloseModal={handleCloseModal}
        deleteSubmit={deleteFactStatus}
        fieldIdentifier={"Factory Status Description"}
      />
      {manageAppSetting && <Button variant="contained" onClick={handleAddClick}>
        Add Factory Status
      </Button>}

      <SettingGrid
        data={rows}
        column={FactStatusGridColumns}
        sortByField="id"
        sortByOrder="desc"
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
