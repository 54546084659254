import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Title, ChartData, ChartOptions, Tooltip, Legend } from 'chart.js';

const CustomLegendMarginPlugin = {
  id: 'customLegendMargin',
  beforeInit(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 20; // Adds margin-bottom to the legend
    };
  },
};

// To display on stack: false
const CustomTotalsPlugin = {
  id: 'custom-totals',
  afterDatasetsDraw(chart) {
    const { ctx, data } = chart;

    ctx.save();

    data.labels.forEach((label, index) => {
      let yOffset = 0; // To manage vertical offset for each dataset total

      data.datasets.forEach((dataset, datasetIndex) => {
        if (!chart.isDatasetVisible(datasetIndex)) return; // Skip hidden datasets

        const meta = chart.getDatasetMeta(datasetIndex);
        const bar = meta.data[index];

        if (!bar) return;

        const value = dataset.data[index];

        if (value === 0) return; // Skip rendering totals for zero values

        const barX = bar.x;
        const barY = bar.y;

        // Render the total above each individual dataset bar
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = 'black'; // Match text color to dataset
        ctx.fillText(value.toString(), barX, barY - 5 - yOffset);

      });

    });

    ctx.restore();
  },
};

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, BarController, CustomLegendMarginPlugin, CustomTotalsPlugin);

interface BarChartProps {
  data: ChartData<'bar'>; // Data structure for a bar chart
  options: ChartOptions<'bar'>; // Options structure for a bar chart
}

const BarChart: React.FC<BarChartProps> = ({ data, options }) => {

  return (
    <Bar data={data} options={options} />
  );
};

export default BarChart;
