import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper, FormControlLabel, Switch, Divider } from "@mui/material";
import { ApiResponse } from "../../models/ApiResponse";
import api from "../../services/ApiService";
import { AssignmentUsersWithCount, ProjectDashboardDetails, StageStatusesWithCount } from "../../models/ProjectDashboardDetails";
import Loader from "../../components/Loader";
import StageStatusChart from "./StageStatusesChart";
import Filters from "../Products/Filters";
import { SelectedFilters } from "../../models/Items/ItemListRequest";
import { Permissions, PermissionsFriendlyNames } from "../../models/Enum";
import { useAppDispatch, useAppSelector } from "../../store";
import { updateProjectChartRequest } from "../../reducers/projectDashboardReducer";
import AssignmentUsersChart from "./AssignmentUsersChart";

const ProjectDashboardOverview: React.FC = () => {

  const [isLoading, setLoading] = useState(false);
  const [assignmentUsersWithCount, setAssignmentUsersWithCount] = useState<AssignmentUsersWithCount[]>(null);
  const [stageStatusesWithCount, setStageStatusesWithCount] = useState<StageStatusesWithCount[]>([]);
  const [activeProjectsCount, setActiveProjectsCount] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

  const projectChartRequestState = useAppSelector((state) => state.projectDashboard.projectChartRequest);

  const projectOverviewDashboardFilterValuesSelected = useAppSelector((state) => state.projectDashboard.activeProjectListFilters.selectedFilterValues);

  const initialProjectDashboardRequest = {
    isPrjCodeWise: projectChartRequestState?.isPrjCodeWise || true,
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    getProjectDashboardDetail();
  }, [projectChartRequestState]);

  // const handleDataChange = () => {
  //   const assignmentChartContainer = document.querySelector('.graph-label-container-assignment');
  //   if (assignmentChartContainer) {
  //     assignmentChartContainer.remove();
  //   }
  
  //   const stageStatusChartContainer = document.querySelector('.graph-label-container-stage-status');
  //   if (stageStatusChartContainer) {
  //     stageStatusChartContainer.remove();
  //   }
  // };

  const getProjectDashboardDetail = async () => {
    // handleDataChange();
    try {
      
      setLoading(true);

      const projectDashboardRequest = { ...projectChartRequestState, ...projectOverviewDashboardFilterValuesSelected };

      api
        .post<ApiResponse<ProjectDashboardDetails>>("/dashboard/project-overview", projectDashboardRequest)
        .then((response) => {
          setLoading(false);
          if (response.isSuccess) {
            setActiveProjectsCount(response?.data?.totalActive);
            setAssignmentUsersWithCount(response?.data?.assignmentUsersCount);
            setStageStatusesWithCount(response?.data?.stageStatusesCount);
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Exception from Project Dashboard Detail API", error);
        });
    } catch (error: any) {
      setLoading(false);
      console.error("Exception from Project Dashboard Detail API", error);
    }
  };

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const handleApplyFilterClick = (filterValues: SelectedFilters) => {
    if (Object.values(filterValues)?.length) {
      dispatch(updateProjectChartRequest({
        ...projectChartRequestState,
        ...filterValues,
      }));
    } else {
      //reset the filters
      dispatch(updateProjectChartRequest(initialProjectDashboardRequest));
    }
  };

  const handleToggleButtonChange = (checked: boolean) => {
    dispatch(updateProjectChartRequest({
      ...projectChartRequestState,
      isPrjCodeWise: checked
    }));
  };

  return (
    <>
      <Filters
        onApplyFilter={handleApplyFilterClick}
        filtersExpanded={filtersExpanded}
        handleExpandClick={handleExpandClick}
        viewMode={PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard]}
      />
      <Loader isLoading={isLoading} />
      <Paper elevation={3} className="project-overview-dashboard-container">
        {activeProjectsCount ? (
          <>
            <Box
              className="header-title-container"
            >
              <Typography variant="h5" className="total-active-projects-title">
                Total Active Projects: {activeProjectsCount}
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    checked={projectChartRequestState?.isPrjCodeWise}
                    onChange={(e) => handleToggleButtonChange(e.target.checked)}
                    color="primary"
                  />
                }
                label={projectChartRequestState?.isPrjCodeWise ? 'Total Project Codes' : 'Total Base Codes'}
              />
            </Box>
            <Grid container spacing={2} className="project-overview-grid-container">
              {assignmentUsersWithCount?.length > 0 && (
                <>
              <Grid item xs={12} md={12} className="project-overview-grid-item">
                  <AssignmentUsersChart key={assignmentUsersWithCount.length} chartData={assignmentUsersWithCount} />
              </Grid>
              <Grid item xs={12} className="project-overview-divider">
                <Divider />
              </Grid>
              </>
        )}
              <Grid item xs={12} md={12} className="project-overview-grid-item">
                {stageStatusesWithCount?.length > 0 &&
                  <StageStatusChart chartData={stageStatusesWithCount} />}
              </Grid>
            </Grid>
          </>) : (
          <Typography variant="h6" className="no-data-found-message">No Data Found for Project Dashboard</Typography>
          )}
      </Paper>
    </>
  );
};

export default ProjectDashboardOverview;
