import { Chip, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import GridCellChipsContent from "./GridCellChipsContent";

export const NO_ROWS_GRID_MESSAGE = (
    <Stack
      style={{
        height: "100px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>No records found</h2>
    </Stack>
);

export const NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE = (
    <Stack
      style={{
        height: "100px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>Local search found no records. Please use filters above</h2>
    </Stack>
  );

  export const GRID_ACTION_EDIT_ICON = (params: any, onClickHandler: any) => (
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Edit"
      onClick={() => onClickHandler(params)}
    />
  );

  export const GRID_ACTION_DELETE_ICON = (params: any, onClickHandler: any) => (
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label="Delete"
      onClick={() => onClickHandler(params)}
    />
  );

  export const GRID_READ_ONLY = () => (
    <Chip label="Read Only" clickable={false}/>
  );
