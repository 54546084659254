import { Button, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid"
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { DataTypeDetail } from "../../../models/DataTypeDetail";
import { useEffect, useState } from "react";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { SessionDetails } from "../../../models/SessionDetails";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import DataTypeModal from "./DataTypeModal";
import * as Yup from "yup"; 
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON, GRID_READ_ONLY } from "../../../components/GridUtilityComponents";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function DataTypeSetting() {
    const [dataTypeList, setDataTypeList] = useState<DataTypeDetail[]>([]);
    const [showAddUUpdateModal, setShowAddUpdateModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const DataTypeColumns : GridColDef [] = [
        { field: "id", headerName: "Id", width: 100},
        { field: "description", headerName: "Data Type", width: 300},
        { field: "pimKey", headerName: "Pim Key", width: 300},
        { field: "isActive", headerName: "Is Active", width: 150, sortable: false,
            renderCell: (params: any) => {
                if(params.row.readOnlyField)
                    return GRID_READ_ONLY();

                return(
                <>
                    <Switch
                        checked = {params.value}
                        onChange = {async (e) => {
                            await updateDataType(params.row, e.target.checked);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "controlled" }}
                        disabled = {!manageAppSetting}
                    />
                </>  
                )
            }
        },
        // { field: "updatedDate", headerName: "Updated Date", width: 200,
        //     valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT)
        // },
        { field: "actions", type: "actions", headerName: "Actions", width: 200,
        getActions: (params: any) => {
            if (params.row.readOnlyField) {
                return [
                    GRID_READ_ONLY()
                ];
            }
            return [
                GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
                GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
            ];
            }
        }
    ];

    const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

    useEffect(() =>{
        fetchData();
    },[]);

    const fetchData = async () => {
        const getDataTypeListResponse = await api.get<ApiResponse<DataTypeDetail[]>>("/Picklist/data-types");
        setDataTypeList(getDataTypeListResponse?.data || []);
    };

    const handleEditClickOpen = (gridParams: any) => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
        formik.setValues({...gridParams.row});
    };

    const handleAddClickOpen = () => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
    };

    const handleDeleteModalOpen = (gridParams: any) => {
        setDeleteModalOpen(true);
        formik.setValues({...gridParams.row}); 
    }

    const handleCloseModal = () => {
        setShowAddUpdateModal(false);
        setDeleteModalOpen(false);
        formik.resetForm();
    };

    const updateDataType = async (row: DataTypeDetail, isActive: boolean) => {
        row.isActive = isActive;
        try{
            api 
                .put<ApiResponse<number>>("/Picklist/data-type", row)
                .then(async (response: any) => {
                    if(response.isSuccess) {                        
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch(() => {});
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const deleteSubmit = async () => {
        try{
            api
                .delete<ApiResponse<SessionDetails>>(`/Picklist/data-type/${formik.values.id}`)
                .then(async (response: any) => {
                    if(response.isSuccess) {
                        setDeleteModalOpen(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch(() => {});
        } catch(err: any) {
            toast.error(err.message);
        }
    };

    const formik = useFormik ({
        initialValues: {
            id: 0,
            description: '',
            pimKey: '',
            isActive: true
        },
        validationSchema: Yup.object({
            description: Yup.string()
                .trim()
                .min(1, "Data Type must be at least 1 characters")
                .max(40, "Data Type must be at most 40 characters")
                .required("Data Type is required")
                .test("check-description-duplicate", "Data Type already exists", 
                (value) => {
                    return !dataTypeList.some(
                        (item) => item.description.trim().toLowerCase() === 
                        value.trim().toLowerCase() && item.id !== formik.values.id
                        )
                }),
            pimKey: Yup.string()
                .trim()
                .min(1, "Pim Key must be at least 1 characters")
                .max(40, "Pim Key must be at most 40 characters")
                .required("Pim Key is required")
                .test("check-pimkey-duplicate", "Pim Key already exists", 
                (value) => {
                    return !dataTypeList.some(
                        (item) => item.pimKey.trim().toLowerCase() === 
                        value.trim().toLowerCase() && item.id !== formik.values.id
                        )
                })
        }),
        onSubmit: async (values) => {
            setDisableSubmit(true);
            if(values.id > 0) {
                api 
                    .put<ApiResponse<number>>("/Picklist/data-type", {
                        id: values.id,
                        description: values.description,
                        pimKey: values.pimKey,
                        isActive: JSON.parse(`${values.isActive}`)
                    })
                    .then(async (response: any) => {
                        if(response.isSuccess) {
                            setShowAddUpdateModal(false);
                            formik.resetForm();
                            toast.success(response.message);
                            await fetchData();
                        } else {
                            throw new Error(response.message);
                        }
                    })
                    .catch(() => {});
            } else {
                api 
                .post<ApiResponse<number>>("/Picklist/data-type", {
                    description: values.description,
                    pimKey: values.pimKey,
                    isActive: JSON.parse(`${values.isActive}`)
                })
                .then(async (response: any) => {
                    if(response.isSuccess) {
                        setShowAddUpdateModal(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch(() => {});
            }
        }
    })


    return (
        <>
            <div>
                {manageAppSetting && <Button variant = "contained" onClick = {handleAddClickOpen}>
                    Add Data Type
                </Button>}
                <DataTypeModal 
                    isOpen={showAddUUpdateModal} 
                    onClose={handleCloseModal} 
                    formik={formik}
                    isButtonDisabled={disableSubmit}
                />
                <DeleteValidationModal 
                    addModalOpen={deleteModalOpen} 
                    handleCloseModal={handleCloseModal} 
                    deleteSubmit={deleteSubmit}
                    fieldIdentifier={"Data Type"}
                />
            </div>
            <SettingGrid 
                data = {dataTypeList} 
                column = {DataTypeColumns}
                sortByField = "id"
                sortByOrder = "desc"
                columnVisibility={{
                    actions: manageAppSetting,
                  }}          
            />
        </>
    )
};